import React, { useEffect } from 'react';
import { Container } from 'react-bootstrap';

import logo from '../logo.svg';

import TagManager from 'react-gtm-module';

const Header = (props) => {
  useEffect(() => {
    const tagManagerArgs = {
      gtmId: 'GTM-WF8J53W4',
    };

    TagManager.initialize(tagManagerArgs);
  }, []);

  return (
    <header
      className='w-100 flex aic jcc'
      style={{
        background: `#444 url(${props.back}) center top no-repeat`,
        backgroundBlendMode: 'overlay',
        backgroundSize: 'cover',
      }}
    >
      <Container>
        <div className='flex aic jcsb mob-header'>
          <h1 className='site-title'>
            <a href='/' aria-label='Advogram Logo'>
              <img src={logo} alt='Advogram' width={50} height={50} />
              <span className='px-3 c-gold'>ADVOGRAM</span>
            </a>
          </h1>
          <div className='top-nav flex jcc aic'>
            <a href='/'>Головна</a>
            <a href='cases'>Кейси</a>
            <a href='ivents'>Івенти</a>
            <a href='advocates'>Адвокатам</a>
            <a href='volunteers'>Волонтерам</a>
          </div>
        </div>
      </Container>
    </header>
  );
};

export { Header };
