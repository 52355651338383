import React from 'react';
import { Helmet } from 'react-helmet';
import { Header } from '../parts/Header';
import bgi1 from '../assets/img/bgi1.webp';
import ipic1 from '../assets/img/ipic1.webp';
import ipic2 from '../assets/img/ipic2.webp';

const gtag_report_conversion = (url) => {
  const callback = function () {
    if (typeof url !== 'undefined') {
      window.location = url;
    }
  };
  window.gtag('event', 'conversion', {
    send_to: 'AW-11099580287/SmzoCOaFrYcZEP_O2Kwp',
    event_callback: callback,
  });
  return false;
};

const handleButtonClick = () => {
  gtag_report_conversion('https://t.ly/6-D2y');
};

const Ivents = () => {
  return (
    <>
      <Helmet>
        <title>Advogram | Підготуйте бізнес до перевірок</title>
        <meta
          name='description'
          content='Підготуйте свій бізнес до перевірок з Advogram. Дізнайтеся, як оформити документи, підтвердити легальність діяльності та уникнути фінансових ризиків. Приєднуйтеся до воркшопу, щоб отримати практичні поради та консультації щодо міжнародних вимог.'
        />
        <meta
          name='keywords'
          content='advogram, invent події, підготовка бізнесу, юридична допомога, перевірка документів, легальність бізнесу, воркшоп, міжнародні вимоги, фінансова безпека, реєстрація організацій, консультації експертів, практичні поради'
        />
        <meta name='theme-color' content='#0C1125' />
        <body className='page' />
      </Helmet>
      <Header back={bgi1} />
      <div id='ivent'>
        <div className='h-156'></div>
        <div className='container tc'>
          <h3 className='title-intro c-gold w-80 ma'>
            Підготуйте бізнес до перевірок
          </h3>
          <h4 className='title-sub c-white w-80 ma my-3 tc'>
            25 лютого, 19:00 (онлайн)
          </h4>
        </div>
        <div className='h-156'></div>
      </div>
      <section id='mission'>
        <div className='container'>
          <div className='s-120'></div>
          <h3 className='title-sub c-gold w-80 ma my-3 tc'>Чому це важливо?</h3>
          <p className='c-dark w-70 ma tc'>
            Закордонні партнери та донори вимагають дедалі більшої прозорості та
            підзвітності від українських організацій. Посилений міжнародний
            контроль означає, що державні органи всередині країни отримують
            вказівки або рекомендації перевіряти діяльність тих, хто отримує або
            розподіляє іноземні кошти. Якщо ви не дотримуєтесь встановлених
            процедур та не маєте належних документів, ризикуєте втратити
            фінансування та зіткнутися з різними санкціями.
          </p>
          <div className='s-60'></div>
          <div className='row'>
            <div className='col-lg-1'></div>
            <div className='col-lg-4'>
              <img
                src={ipic1}
                alt='Станьте Частиною Advogram як Адвокат'
                width={400}
                height={280}
                className='obfc'
              />
            </div>
            <div className='col-lg-1'></div>
            <div className='col-lg-5 flex jcc aic'>
              <div>
                <h3 className='title-sub c-dark my-3'>Що ви дізнаєтесь?</h3>
                <div className='s-10'></div>
                <p className='c-dark'>
                  – Як правильно оформити документи, щоб підтвердити легальність
                  вашої діяльності
                </p>
                <p className='c-dark'>
                  – Як зареєструватися у міжнародних списках організацій (ці
                  списки підтверджують, що ви надійний партнер для закордонних
                  інвесторів та донорів)
                </p>
                <p className='c-dark'>
                  – Які практичні кроки допоможуть уникнути блокування грошей
                  або призупинення співпраці
                </p>
              </div>
            </div>
            <div className='col-lg-1'></div>
          </div>
          <div className='s-80'></div>
          <div className='row'>
            <div className='col-lg-1'></div>
            <div className='col-lg-5 flex jcc aic'>
              <div>
                <h3 className='title-sub c-dark my-3'>Для кого це?</h3>
                <div className='s-10'></div>
                <p className='c-dark'>
                  – Підприємців, які отримують або планують отримувати підтримку
                  від закордонних партнерів
                </p>
                <p className='c-dark'>
                  – Юридичних та фінансових фахівців, що працюють з міжнародними
                  проєктами
                </p>
                <p className='c-dark'>
                  – Громадських організацій, які залучають іноземні ресурси для
                  реалізації своїх ініціатив
                </p>
              </div>
            </div>
            <div className='col-lg-4'>
              <img
                src={ipic2}
                alt='Станьте Частиною Advogram як Адвокат'
                width={400}
                height={280}
                className='obfc'
              />
            </div>
          </div>
        </div>
        <div className='s-120'></div>
        <h3 className='title-sub c-gold w-80 ma my-3 tc'>
          Ваші формати участі
        </h3>
        <div className='s-40'></div>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-2'></div>
            <div className='col-lg-4'>
              <h3 className='title-sub c-dark my-3'>
                Базовий квиток (500 грн)
              </h3>
              <div className='s-10'></div>
              <div className='h-100'>
                <p className='c-dark'>– Участь у воркшопі</p>
                <p className='c-dark'>
                  – Практичні матеріали для самостійного впровадження
                </p>
              </div>
              <div className='s-80'></div>
              <a
                href='https://secure.wayforpay.com/payment/advogram-workshop1'
                target='blank'
                onClick={handleButtonClick}
                className='button small c-dark bg-gold py-2 px-4'
              >
                Замовити квиток
              </a>
              <div className='s-60'></div>
            </div>
            <div className='col-lg-6'>
              <h3 className='title-sub c-dark my-3'>
                Розширений квиток (3500 грн)
              </h3>
              <div className='s-10'></div>
              <div className='h-100'>
                <p className='c-dark'>– Участь у воркшопі</p>
                <p className='c-dark'>– Практичні матеріали</p>
                <p className='c-dark'>
                  – Персональна консультація експерта для вашої організації
                  (розбір документів, поради щодо реєстрації в міжнародних
                  списках, покрокові рекомендації)
                </p>
              </div>
              <div className='s-80'></div>
              <a
                href='https://secure.wayforpay.com/payment/advogram-workshop1'
                target='blank'
                onClick={handleButtonClick}
                className='button small c-dark bg-gold py-2 px-4'
              >
                Замовити квиток
              </a>
              <div className='s-60'></div>
            </div>
          </div>
        </div>
        <div className='s-100'></div>
        <div id='ticket'>
          <div className='s-120'></div>
          <div className='container tc'>
            <h3 className='title-intro c-gold w-80 ma'>Не зволікайте!</h3>
            <div className='s-40'></div>
            <p className='title-intro-sub my-3 w-80 ma c-white'>
              Підготуйте свій бізнес до посиленого контролю, щоб убезпечити себе
              від раптових перевірок та втрат коштів. Всі ваші інвестиції в
              участь підуть на підтримку нашої місії – підвищення правової
              обізнаності та доступ до якісної юридичної допомоги.
            </p>
            <div className='s-40'></div>
            <a
              href='https://secure.wayforpay.com/payment/advogram-workshop1'
              target='blank'
              onClick={handleButtonClick}
              className='button small c-dark bg-gold w-30 flex jcc aic ma'
            >
              Зареєструватися на воркшоп
            </a>
          </div>
          <div className='s-120'></div>
        </div>
      </section>
    </>
  );
};

export { Ivents };
